import { createSlice } from "@reduxjs/toolkit"
import { IAuthReducer } from "../../../Interface"

const initiaState: IAuthReducer = {
  accessToken: null,
  refreshToken: null,
  user: null
}

const AuthReducer = createSlice({
  name: "auth",
  initialState: initiaState,
  reducers: {
    setUser(state: IAuthReducer, action) {
      state.user = action.payload
    },
    setCredentials(state: IAuthReducer, action) {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
    resetCredentials: (state: IAuthReducer) => {
      state.accessToken = null
      state.refreshToken = null
      state.user = null
    }
  }
})

export const { setCredentials, resetCredentials, setUser } = AuthReducer.actions

export default AuthReducer.reducer
