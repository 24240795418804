import { useSelector } from "react-redux"
import { RootState } from "../../Interface"
import { Navigate } from "react-router-dom"


function PrivateRouterRender({ children }: React.PropsWithChildren): any {
  const auth = useSelector(
    (state: RootState) => state.auth
  )
  if (!auth.accessToken) {
    return <Navigate to={"/login"} replace />
  }
  else {
    return <>
      {children}
    </>
  }
}

export default PrivateRouterRender 
