import { BrowserRouter, Route, Routes } from "react-router-dom"
import PrivateRouterRender from "./PublicRouteRender/PrivateRouterRender"
import PrivateRoute from "./PrivateRoute/PrivateRoute"
import PublicRouteRender from "./PrivateRouterRender/PublicRouteRender"
import PublicRoute from "./PublicRoute/PublicRoute"
import TestPage from "../Page/TestPage/TestPage"


function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={
                        <PrivateRouterRender>
                            <PrivateRoute />
                        </PrivateRouterRender>
                    }
                >
                    {/* <Route path="/" element={<HomePage />} /> */}
                    <Route path="/" element={<TestPage />} />
                </Route>
                <Route element={<PublicRouteRender>
                    <PublicRoute />
                </PublicRouteRender>}
                >
                    {/* <Route path="/login" element={<LoginPage />} /> */}
                    <Route path="/login" element={<TestPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
export default Router